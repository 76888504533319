import { computed, Injectable, signal } from '@angular/core';
import { filter, map, mergeMap, startWith, Subject } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LngLat } from '@yandex/ymaps3-types';
import { TAnnouncementsListData } from '@components/pages/home/core/announcementsListTypes';

@Injectable({
  providedIn: 'root',
})
export class MapLabelsService {
  #closeLabel = new Subject<void>();
  closeLabel$ = this.#closeLabel.asObservable();

  #mapMoved = new Subject<void>();
  mapMoved$ = this.#mapMoved.asObservable();

  #coordinatesClicked = new Subject<LngLat>();
  coordinatesClicked$ = this.#coordinatesClicked.asObservable();

  #currentClusterAdverts = signal<TAnnouncementsListData>([]);
  currentClusterAdverts = computed(this.#currentClusterAdverts);

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) {}

  closeLabels() {
    this.#closeLabel.next();
  }

  mapMovedEmit() {
    this.#mapMoved.next();
  }

  clickedOn(coords: [string | undefined, string | undefined]) {
    if (coords[0] && coords[1]) {
      this.#coordinatesClicked.next([+coords[0], +coords[1]]);
    }
  }

  urlParams = toSignal(
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      startWith(this.router),
      map(() => this.route),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      mergeMap((route) => route.paramMap),
    ),
  );

  mapUrl = computed<string[]>(() => {
    const url = ['/map'];
    const categoryCode = this.urlParams()?.get('categoryCode');
    const cerealId = this.urlParams()?.get('cerealSlug');

    if (categoryCode) {
      url.push(categoryCode);

      if (cerealId) {
        url.push(cerealId);
      }
    }

    return url;
  });

  setSelectedInClusterAnnouncements(
    announcements: TAnnouncementsListData,
  ): void {
    this.#currentClusterAdverts.set([...announcements]);
  }
}
